import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

const IndexPage = ({ location }) => (
  <Layout location={location}>
    <div>
      <SEO title="Home" />
      <h1>Schon wieder ein neuer Verein?</h1>
      <p>
        Wir haben eine Vision davon, wie der Verein sein soll. Und das wollen
        wir keinem anderen überstülpen oder aufzwingen, sondern frisch und frei
        neu anfangen und entwickeln. Gemeinsam mit Kickerfreunden, die unsere
        Vorstellung von Sport, Leidenschaft und Vereinsleben teilen.
      </p>
      <h2>Vision "kombinat kickers mannheim"</h2>
      <p>
        Die "kombinat kickers Mannheim" sind ein aktiver Verein mit lebendigem
        Vereinsleben sein. Dazu gehört für jedes Mitglied die Möglichkeit,
        regelmäßig am Training sowie Turnieren, Ligaspielen und
        Vereinsveranstaltungen teilnehmen zu können und zu wollen. Wir möchten
        interessierten und engagierten Hobbyspielern ein Zuhause bieten und
        leidenschaftlichen Sportlern die Gelegenheit geben, sich im Verein
        weiter zu entwickeln und ihr Wissen zu teilen.
      </p>
      <h2>Was passiert als nächstes?</h2>
      <p>
        Die Vereinsgründung ist erfolgt. Hurra! Die erste Hürde zur
        Verwirklichung unserer Vision ist damit genommen. Als nächstes stehen
        nun die Anerkennung unserer Gemeinnützigkeit, sowie die Eintragung ins
        Vereinsregister auf dem Programm.
      </p>
      <p>
        Um unserer Vorstellung des aktiven Vereinslebens und der Förderung
        unseres Sportes näher zu kommen, haben wir für die Saison 2020 eine
        Mannschaft in die Bezirksliga des{" "}
        <a href="https://tfvbw.de" rel="noopener noreferrer">
          Tischfußballverbands Baden-Württemberg e.V.
        </a>{" "}
        gemeldet. Du möchtest uns unterstützen? Super! Sprich uns einfach bei
        einem Training an, oder melde dich über das{" "}
        <Link to="/kontakt">Kontaktformular</Link>.
      </p>
      <p>
        Wir freuen uns darauf, zukünftig mit euch gemeinsam einen neuen
        Mannheimer Kickerverein zum Erfolg zu führen.
      </p>
      <p> Theresa, Max und Jörn</p>
    </div>
  </Layout>
);

export default IndexPage;
